@import url("https://fonts.googleapis.com/css2?family=Mogra&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #fff !important;
  color: rgb(10, 30, 60);
}

h1 {
  color: rgb(5, 15, 30, 0.8);
  font-size: 4.5rem;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 4rem;

  display: flex;
  mix-blend-mode: normal;
  justify-content: center;
  pointer-events: none;
  z-index: -5;
  font-family: Roboto, sans-serif;
  top: 60;
  font-weight: 600;
  text-align: left;
}

.h1-sub {
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(24, 24, 24, 0.8);
  text-align: left;
  padding: 50px 0 20px 0;
}

h2 {
  color: rgb(10, 30, 60);
  font-size: 2.5rem;
  font-weight: 500;
}

/* Shadows */
.shadow_medium {
  box-shadow: 0px 16px 20px -8px rgba(0, 0, 0, 0), 0px 13px 27px -12px rgba(50, 50, 90, 1);
}
.shadow_large {
  box-shadow: 0px 18px 36px -18px rgba(0, 0, 0, 0.2), 0px 30px 45px -30px rgba(50, 50, 93, 0.25);
}

.fade-in {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 900px) {
  .hideOnMobile {
    display: none;
  }
}
